/* eslint-disable max-lines */
import {
  checkoutOpenCloseYourOrderSummaryInMobileParams,
  checkoutShowHideItemsSectionInMobileViewParams,
  errorInCheckoutParams,
} from '@wix/bi-logger-ecom-platform-data/v2/types';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';

import {
  BiMobilePosition,
  cantShipToDestinationBaseParams,
  ECOM_PLATFORM_CHECKOUT,
  getAdditionalFeesPrice,
  getCartType,
  getCatalogAppIds,
  getCouponErrorCodeForBi,
  getCustomFieldsTitles,
  getFirstStage,
  getFlowType,
  getItemTypes,
  getNumberOfAdditionalFees,
  getNumOfShippingOptions,
  getPaymentCategory,
  getSelectedShippingMethodType,
  getSelectedShippingOptionIndex,
  getShippingOptionTypes,
  getValidations,
  hasPickupOption,
  hasSavedAddress,
  isCustomFieldMandatory,
  isFullyPaidByGiftCard,
  isWithOfflinePaymentMethod,
  ShippingMethodType,
} from '../../utils/bi.util';
import {CheckoutModel} from '../../models/checkout/Checkout.model';
import {CheckoutErrorModel} from '../../models/checkout/CheckoutError.model';
import {NavigationService} from './NavigationService';
import {CheckoutSettingsService} from './CheckoutSettingsService';
import {shouldShowGiftCardSection} from '../../utils/shouldShowGiftCardSection';
import {shouldShowCouponSection} from '../../utils/shouldShowCouponSection';
import {MemberAddressActionBiParams, StepId} from '../../../types/checkoutApp.types';
import {MemberService} from './MemberService';
import {
  checkoutClickOnCheckboxInReviewAndPlaceOrderStepSrc130Evid2,
  checkoutClickToRemoveItemDueToCheckoutRestrictionsSrc130Evid74,
  clickOnContinueShoppingFromCart,
  checkoutMemberClickOnEditMemberAddress,
  checkoutBillingAddressAsDeliverAddress,
  checkoutMemberMemberAddressUpdated,
  checkoutMemberClickOnAddNewAddress,
  checkoutMemberMemberAddressCreated,
  clickOnEditCart,
  errorInCheckout,
  shippingAddressSet,
  checkoutPageSpecificStageExpanded,
  clickOnBackInCheckoutProcess,
  checkoutTotalsHaveBeenUpdatedPopUpShown,
  checkoutClickOnCtaInTotalsHaveBeenUpdatedPopup,
  deliveryMethodSet,
  clickPlaceOrder,
  clickToAddCoupon,
  checkoutOpenCloseYourOrderSummaryInMobile,
  checkoutShowHideItemsSectionInMobileView,
  clickApplyCoupon,
  removeACoupon,
  couponApplied,
  errorWhenApplyingACoupon,
  giftCardCheckoutClickOnCheckbox,
  giftCardCheckoutClickApply,
  giftCardCheckoutRemoveCode,
  giftCardCheckoutCodeApplied,
  checkoutErrorWhenApplyingAGiftCard,
  checkoutMinimumOrderModalIsShownInCheckout,
  checkoutMinimumOrderClickOnGotItInErrorModalInCheckout,
  checkoutErrorTrackingForDevelopers,
  clickPlaceOrderWithSubscription,
  checkoutClickOnCheckoutPolicies,
  checkoutLoadingCheckoutPageFailForDev,
  checkoutPageLoad,
  checkoutIntegratingFormsFailure,
  selectPaymentCategory,
  paymentDetailsCompleted,
  checkoutClickLoginInCheckout,
  clickOnCheckoutWithEWallet,
  clickLogoutInCheckout,
  clickOnReturnToCheckoutInLogoutModal,
  checkoutCheckoutPageFinishLoadingSrc130Evid54,
} from '@wix/bi-logger-ecom-platform-data/v2';
import {CheckoutOrigin, toBiAmount} from '../../utils/common/bi.util.common';
import {FormsService} from './FormsService';
import {isEligibleForLeanFlow} from '../../utils/isEligibleForLeanFlow.utils';

export class BIService {
  constructor(
    private readonly services: {
      siteStore: SiteStore;
      navigationService: NavigationService;
      checkoutSettingsService: CheckoutSettingsService;
      memberService: MemberService;
      formsService: FormsService;
    }
  ) {}

  public clickOnContinueShopping(checkout: CheckoutModel, origin: CheckoutOrigin = CheckoutOrigin.checkout): void {
    void this.services.siteStore.webBiLogger.report(
      clickOnContinueShoppingFromCart({
        additionalFeesPrice: getAdditionalFeesPrice(checkout),
        appName: ECOM_PLATFORM_CHECKOUT,
        cartId: checkout.cartId,
        catalogAppId: getCatalogAppIds(checkout),
        checkoutId: checkout.id,
        ecomId: checkout.ecomId,
        itemType: getItemTypes(checkout),
        numberOfAdditionalFees: getNumberOfAdditionalFees(checkout),
        origin,
      })
    );
  }

  public checkoutBillingAddressAsDeliverAddress(checkout: CheckoutModel): void {
    const paymentMethods = this.services.checkoutSettingsService?.checkoutSettings?.activePaymentMethods
      ?.map(/* istanbul ignore next: can't mock with payments testkit */ ({name}) => name)
      .join(',');

    void this.services.siteStore.webBiLogger.report(
      checkoutBillingAddressAsDeliverAddress({
        billingCountry: checkout.billingInfo?.address?.country,
        shippingCountry: checkout.shippingDestination?.address?.country,
        purchaseFlowId: checkout.purchaseFlowId,
        cartType: getCartType(checkout),
        paymentMethods,
      })
    );
  }

  public memberClickOnEditAddress(params: MemberAddressActionBiParams): void {
    const {checkout, ...rest} = params;

    void this.services.siteStore.webBiLogger.report(
      checkoutMemberClickOnEditMemberAddress({
        appName: ECOM_PLATFORM_CHECKOUT,
        cartId: checkout.cartId,
        catalogAppId: getCatalogAppIds(checkout),
        checkoutId: checkout.id,
        cartType: getCartType(checkout),
        ecomId: checkout.ecomId,
        ...rest,
      })
    );
  }
  public memberClickOnAddAddress(params: MemberAddressActionBiParams): void {
    const {checkout, addressServiceId, ...rest} = params;

    void this.services.siteStore.webBiLogger.report(
      checkoutMemberClickOnAddNewAddress({
        appName: ECOM_PLATFORM_CHECKOUT,
        cartId: checkout.cartId,
        catalogAppId: getCatalogAppIds(checkout),
        checkoutId: checkout.id,
        cartType: getCartType(checkout),
        ecomId: checkout.ecomId,
        ...rest,
      })
    );
  }

  public memberAddressUpdated(
    checkout: CheckoutModel,
    addressId: string,
    stepId: string,
    isSetAsDefaultChecked: boolean
  ): void {
    void this.services.siteStore.webBiLogger.report(
      checkoutMemberMemberAddressUpdated({
        appName: ECOM_PLATFORM_CHECKOUT,
        cartId: checkout.cartId,
        catalogAppId: getCatalogAppIds(checkout),
        checkoutId: checkout.id,
        cartType: getCartType(checkout),
        addressId,
        stage: stepId,
        ecomId: checkout.ecomId,
        isSetAsDefaultChecked,
      })
    );
  }

  public memberAddressCreated(
    checkout: CheckoutModel,
    addressServiceId: string,
    stepId: string,
    isSetAsDefaultChecked: boolean
  ): void {
    void this.services.siteStore.webBiLogger.report(
      checkoutMemberMemberAddressCreated({
        appName: ECOM_PLATFORM_CHECKOUT,
        cartId: checkout.cartId,
        catalogAppId: getCatalogAppIds(checkout),
        checkoutId: checkout.id,
        cartType: getCartType(checkout),
        ecomId: checkout.ecomId,
        isSetAsDefaultChecked,
        stage: stepId,
        addressServiceId,
        purchaseFlowId: checkout.purchaseFlowId,
      })
    );
  }

  public clickOnEditCart(checkout: CheckoutModel, mobilePosition?: string): void {
    void this.services.siteStore.webBiLogger.report(
      clickOnEditCart({
        appName: ECOM_PLATFORM_CHECKOUT,
        cartId: checkout.cartId,
        ecomId: checkout.ecomId,
        additionalFeesPrice: getAdditionalFeesPrice(checkout),
        cartType: getCartType(checkout),
        catalogAppId: getCatalogAppIds(checkout),
        checkoutId: checkout.id,
        itemType: getItemTypes(checkout),
        numberOfAdditionalFees: getNumberOfAdditionalFees(checkout),
        origin: 'editCartButton',
        mobilePosition,
        purchaseFlowId: checkout.purchaseFlowId,
      })
    );
  }

  public sendCantShipToDestinationBIEvent(checkout: CheckoutModel): void {
    this.sendErrorInCheckoutBIEvent(checkout, cantShipToDestinationBaseParams());
  }

  public sendFailedToCompleteOrderBIEvent(checkout: CheckoutModel, params: Partial<errorInCheckoutParams>) {
    this.sendErrorInCheckoutBIEvent(checkout, params);
  }

  public sendOnMobileFoldableSummaryToggle(
    checkout: CheckoutModel,
    mobilePosition: BiMobilePosition,
    partialParams:
      | Partial<checkoutOpenCloseYourOrderSummaryInMobileParams>
      | Partial<checkoutShowHideItemsSectionInMobileViewParams>
  ) {
    const catalogAppId = getCatalogAppIds(checkout);
    const params = {
      ...partialParams,
      catalogAppId,
      purchaseFlowId: checkout.purchaseFlowId,
    };

    mobilePosition === BiMobilePosition.top
      ? void this.services.siteStore.webBiLogger.report(checkoutOpenCloseYourOrderSummaryInMobile(params))
      : void this.services.siteStore.webBiLogger.report(checkoutShowHideItemsSectionInMobileView(params));
  }

  public sendCheckoutErrorBIEvent(checkout: CheckoutModel, params: Partial<errorInCheckoutParams>) {
    this.sendErrorInCheckoutBIEvent(checkout, params);
  }

  public sendErrorInCheckoutBIEvent(checkout: CheckoutModel, params: errorInCheckoutParams): void {
    const catalogAppId = getCatalogAppIds(checkout);
    const contactId = checkout.buyerInfo?.contactId;
    const customFieldsTitle = getCustomFieldsTitles(checkout);
    const itemType = getItemTypes(checkout);
    const additionalFeesPrice = getAdditionalFeesPrice(checkout);
    const numberOfAdditionalFees = getNumberOfAdditionalFees(checkout);
    const buyerLanguage = checkout.buyerLanguage;
    const isBuyNowPayLaterOption = Boolean(checkout.payLater?.total.amount);
    const num_of_paymet_providers = this.services.checkoutSettingsService.checkoutSettings.activePaymentMethods?.length;
    const num_of_shipping = getNumOfShippingOptions(checkout);
    const numLineItems = checkout.lineItems.length;
    const shippingMethodType = getSelectedShippingMethodType(checkout.selectedShippingOption);
    const siteLanguage = checkout.siteLanguage;
    const savedAddress = hasSavedAddress(checkout);
    const isLean = isEligibleForLeanFlow({
      checkout,
      hasLeanFlowParam: this.services.navigationService.hasLeanFlowParam,
      isMember: this.services.memberService.isMember(),
      formsService: this.services.formsService,
    });

    void this.services.siteStore.webBiLogger.report(
      errorInCheckout({
        appName: ECOM_PLATFORM_CHECKOUT,
        cartId: checkout.cartId,
        catalogAppId,
        channelType: checkout.channelType,
        checkoutId: checkout.id,
        ecomId: checkout.ecomId,
        contactId,
        customFieldsTitle,
        flowType: getFlowType(this.services.navigationService.isFastFlow, isLean),
        is_promo_code: shouldShowCouponSection(this.services.checkoutSettingsService.showCouponSP, checkout),
        is_with_ewallet_payment: true,
        is_with_offline_payment: isWithOfflinePaymentMethod(
          this.services.checkoutSettingsService.checkoutSettings.activePaymentMethods
        ),
        isCalculationErrors: checkout.errors.hasCalculationErrors,
        isGiftCard: shouldShowGiftCardSection({
          checkoutSettings: this.services.checkoutSettingsService.checkoutSettings,
          checkout,
        }),
        isMember: this.services.memberService.isMember(),
        isPolicy: this.services.checkoutSettingsService.checkoutSettings.isThereAnyPolicyEnabled,
        itemType,
        origin: this.services.navigationService.origin,
        originType: this.services.navigationService.originType,
        additionalFeesPrice,
        numberOfAdditionalFees,
        validationMessage: getValidations(checkout.violations),
        buyerLanguage,
        isBuyNowPayLaterOption,
        num_of_paymet_providers,
        num_of_shipping,
        numLineItems,
        shippingMethodType,
        siteLanguage,
        savedAddress,
        PurchaseFlowId: checkout.purchaseFlowId,
        ...params,
      })
    );
  }

  public shippingAddressSet(
    checkout: CheckoutModel,
    isBillingAddressTheSameAsShippingAddress: boolean,
    isFormValid: boolean
  ): void {
    const catalogAppId = getCatalogAppIds(checkout);
    const cartType = getCartType(checkout);
    const itemType = getItemTypes(checkout);

    void this.services.siteStore.webBiLogger.report(
      shippingAddressSet({
        appName: ECOM_PLATFORM_CHECKOUT,
        checkoutId: checkout.id,
        cartId: checkout.cartId,
        ecomId: checkout.ecomId,
        numberOfAdditionalFees: getNumberOfAdditionalFees(checkout),
        additionalFeesPrice: getAdditionalFeesPrice(checkout),
        catalogAppId,
        cartType,
        isChecked: isBillingAddressTheSameAsShippingAddress,
        item_type: itemType,
        isFormValid,
        purchaseFlowId: checkout.purchaseFlowId,
      })
    );
  }

  public sendStageExpanded(checkout: CheckoutModel, stepId: StepId, isFirstTime: boolean): void {
    const shippingMethodType = getSelectedShippingMethodType(checkout.selectedShippingOption);
    const catalogAppId = getCatalogAppIds(checkout);
    const cartType = getCartType(checkout);
    const itemType = getItemTypes(checkout);
    const isLean = isEligibleForLeanFlow({
      checkout,
      hasLeanFlowParam: this.services.navigationService.hasLeanFlowParam,
      isMember: this.services.memberService.isMember(),
      formsService: this.services.formsService,
    });

    void this.services.siteStore.webBiLogger.report(
      checkoutPageSpecificStageExpanded({
        appName: ECOM_PLATFORM_CHECKOUT,
        cartId: checkout.cartId,
        checkoutId: checkout.id,
        ecomId: checkout.ecomId,
        cartType,
        isFirstTimeUsage: isFirstTime,
        flowType: getFlowType(this.services.navigationService.isFastFlow, isLean),
        stage: stepId,
        is_member: this.services.memberService.isMember(),
        itemsCount: checkout.lineItems.length,
        catalogAppId,
        itemType,
        numberOfAdditionalFees: getNumberOfAdditionalFees(checkout),
        additionalFeesPrice: getAdditionalFeesPrice(checkout),
        hasGiftCard: shouldShowGiftCardSection({
          checkoutSettings: this.services.checkoutSettingsService.checkoutSettings,
          checkout,
        }),
        isGiftCardApplied: !!checkout.giftCard,
        num_of_shipping: getNumOfShippingOptions(checkout),
        isCartPaidOnlyWithGiftCard: checkout.payNowTotalAfterGiftCard.amount === 0 && !!checkout.giftCard,
        shippingMethodType,
        validationMessage: getValidations(checkout.violations),
        hasRequiredPaymentPolicy: checkout.paymentPolicies.length > 0,
        purchaseFlowId: checkout.purchaseFlowId,
      })
    );
  }

  public sendCheckoutTotalsHaveBeenUpdatedPopUpShown(checkout: CheckoutModel): void {
    const isLean = isEligibleForLeanFlow({
      checkout,
      hasLeanFlowParam: this.services.navigationService.hasLeanFlowParam,
      isMember: this.services.memberService.isMember(),
      formsService: this.services.formsService,
    });

    void this.services.siteStore.webBiLogger.report(
      checkoutTotalsHaveBeenUpdatedPopUpShown({
        appName: ECOM_PLATFORM_CHECKOUT,
        checkoutId: checkout.id,
        purchaseFlowId: checkout.purchaseFlowId,
        flowType: getFlowType(this.services.navigationService.isFastFlow, isLean),
        originType: this.services.navigationService.originType,
      })
    );
  }

  public sendCheckoutClickOnCtaInTotalsHaveBeenUpdatedPopup(checkout: CheckoutModel): void {
    const isLean = isEligibleForLeanFlow({
      checkout,
      hasLeanFlowParam: this.services.navigationService.hasLeanFlowParam,
      isMember: this.services.memberService.isMember(),
      formsService: this.services.formsService,
    });

    void this.services.siteStore.webBiLogger.report(
      checkoutClickOnCtaInTotalsHaveBeenUpdatedPopup({
        appName: ECOM_PLATFORM_CHECKOUT,
        checkoutId: checkout.id,
        purchaseFlowId: checkout.purchaseFlowId,
        flowType: getFlowType(this.services.navigationService.isFastFlow, isLean),
        originType: this.services.navigationService.originType,
      })
    );
  }

  public sendEditStepClicked(checkout: CheckoutModel, stepId: StepId, previousStepName?: StepId): void {
    const catalogAppId = getCatalogAppIds(checkout);
    const itemType = getItemTypes(checkout);

    void this.services.siteStore.webBiLogger.report(
      clickOnBackInCheckoutProcess({
        appName: ECOM_PLATFORM_CHECKOUT,
        cartId: checkout.cartId,
        checkoutId: checkout.id,
        ecomId: checkout.ecomId,
        stage: stepId,
        previousStage: previousStepName,
        catalogAppId,
        itemType,
        numberOfAdditionalFees: getNumberOfAdditionalFees(checkout),
        additionalFeesPrice: getAdditionalFeesPrice(checkout),
        purchaseFlowId: checkout.purchaseFlowId,
      })
    );
  }

  public deliveryMethodSet(
    checkout: CheckoutModel,
    originalShippingTitle: string,
    isSetFromSlot: boolean = false
  ): void {
    const catalogAppId = getCatalogAppIds(checkout);
    const cartType = getCartType(checkout);
    const itemType = getItemTypes(checkout);
    const shippingOptionTypes = getShippingOptionTypes(checkout);
    const selectedShippingOptionIndex = getSelectedShippingOptionIndex(checkout);

    const methodType = checkout.selectedShippingOption?.isPickup
      ? ShippingMethodType.pickup
      : ShippingMethodType.nonPickup;

    void this.services.siteStore.webBiLogger.report(
      deliveryMethodSet({
        appName: ECOM_PLATFORM_CHECKOUT,
        checkoutId: checkout.id,
        cartId: checkout.cartId,
        ecomId: checkout.ecomId,
        numberOfAdditionalFees: getNumberOfAdditionalFees(checkout),
        additionalFeesPrice: getAdditionalFeesPrice(checkout),
        catalogAppId,
        cartType,
        optionscount: checkout.shippingOptions.length + checkout.pickupOptions.length,
        selectedoptionindex: selectedShippingOptionIndex,
        itemType,
        methodType,
        selectedoptiontype: checkout.selectedShippingOption?.title,
        shippingRuleOptions: shippingOptionTypes,
        orig_shipping_method: originalShippingTitle,
        isSetFromSlot,
        purchaseFlowId: checkout.purchaseFlowId,
      })
    );
  }

  public clickPlaceOrder(checkout: CheckoutModel, stepsList: StepId[]): void {
    const shippingMethodType = getSelectedShippingMethodType(checkout.selectedShippingOption);
    const additionalFeesPrice = getAdditionalFeesPrice(checkout);
    const numberOfAdditionalFees = getNumberOfAdditionalFees(checkout);
    const firstStage = getFirstStage(this.services.navigationService.isFastFlow, stepsList[0]);
    const isLean = isEligibleForLeanFlow({
      checkout,
      hasLeanFlowParam: this.services.navigationService.hasLeanFlowParam,
      isMember: this.services.memberService.isMember(),
      formsService: this.services.formsService,
    });

    void this.services.siteStore.webBiLogger.report(
      clickPlaceOrder({
        appName: ECOM_PLATFORM_CHECKOUT,
        cartId: checkout.cartId,
        cartType: getCartType(checkout),
        catalogAppId: getCatalogAppIds(checkout),
        channelType: checkout.channelType,
        checkoutId: checkout.id,
        ecomId: checkout.ecomId,
        contactId: checkout.buyerInfo.contactId,
        couponCode: checkout.appliedCoupon?.code,
        customFieldMandatory: isCustomFieldMandatory(this.services.checkoutSettingsService.checkoutSettings),
        customFieldsTitle: getCustomFieldsTitles(checkout),
        flowType: getFlowType(this.services.navigationService.isFastFlow, isLean),
        is_promo_code: shouldShowCouponSection(this.services.checkoutSettingsService.showCouponSP, checkout),
        is_member: this.services.memberService.isMember(),
        is_with_ewallet_payment: true,
        is_with_offline_payment: isWithOfflinePaymentMethod(
          this.services.checkoutSettingsService.checkoutSettings.activePaymentMethods
        ),
        isGiftCard: shouldShowGiftCardSection({
          checkoutSettings: this.services.checkoutSettingsService.checkoutSettings,
          checkout,
        }),
        isPolicy: this.services.checkoutSettingsService.checkoutSettings.isThereAnyPolicyEnabled,
        itemType: getItemTypes(checkout),
        numOfLineItems: checkout.lineItems.length,
        origin: this.services.navigationService.origin,
        originType: this.services.navigationService.originType,
        additionalFeesPrice,
        numberOfAdditionalFees,
        shippingMethodType,
        first_stage: firstStage,
        num_of_shipping: getNumOfShippingOptions(checkout),
        selectedCarrierServiceOption: checkout.selectedShippingOption?.title,
        shippingCity: checkout.shippingDestination?.address?.city,
        shippingCountry: checkout.shippingDestination?.address?.country,
        siteLanguage: checkout.siteLanguage,
        paymet_providers: this.services.checkoutSettingsService.checkoutSettings.paymentProviders,
        validationMessage: getValidations(checkout.violations),
        isRequiredPaymentPolicy: checkout.paymentPolicies.length > 0,
        purchaseFlowId: checkout.purchaseFlowId,
      })
    );
  }

  public clickToAddCoupon({
    checkout,
    origin = CheckoutOrigin.checkout,
    mobilePosition,
  }: {
    checkout: CheckoutModel;
    origin?: CheckoutOrigin;
    mobilePosition?: string;
  }): void {
    void this.services.siteStore.webBiLogger.report(
      clickToAddCoupon({
        appName: ECOM_PLATFORM_CHECKOUT,
        cartId: checkout.cartId,
        catalogAppId: getCatalogAppIds(checkout),
        checkoutId: checkout.id,
        itemType: getItemTypes(checkout),
        origin,
        mobilePosition,
        purchaseFlowId: checkout.purchaseFlowId,
        isMember: this.services.memberService.isMember(),
      })
    );
  }

  public clickApplyCoupon({
    couponCode,
    checkout,
    origin = CheckoutOrigin.checkout,
    mobilePosition,
  }: {
    couponCode: string;
    checkout: CheckoutModel;
    origin?: CheckoutOrigin;
    mobilePosition?: string;
  }): void {
    void this.services.siteStore.webBiLogger.report(
      clickApplyCoupon({
        additionalFeesPrice: getAdditionalFeesPrice(checkout),
        appName: ECOM_PLATFORM_CHECKOUT,
        cartId: checkout.cartId,
        catalogAppId: getCatalogAppIds(checkout),
        checkoutId: checkout.id,
        couponCode,
        itemType: getItemTypes(checkout),
        numberOfAdditionalFees: getNumberOfAdditionalFees(checkout),
        origin,
        mobilePosition,
        purchaseFlowId: checkout.purchaseFlowId,
        isMember: this.services.memberService.isMember(),
      })
    );
  }

  public removeACoupon({
    checkout,
    origin = CheckoutOrigin.checkout,
    mobilePosition,
  }: {
    checkout: CheckoutModel;
    origin?: CheckoutOrigin;
    mobilePosition?: string;
  }): void {
    void this.services.siteStore.webBiLogger.report(
      removeACoupon({
        additionalFeesPrice: getAdditionalFeesPrice(checkout),
        appName: ECOM_PLATFORM_CHECKOUT,
        cartId: checkout.cartId,
        catalogAppId: getCatalogAppIds(checkout),
        checkoutId: checkout.id,
        couponCode: checkout.appliedCoupon?.code,
        couponId: checkout.appliedCoupon?.id,
        itemType: getItemTypes(checkout),
        numberOfAdditionalFees: getNumberOfAdditionalFees(checkout),
        origin,
        mobilePosition,
        purchaseFlowId: checkout.purchaseFlowId,
        isMember: this.services.memberService.isMember(),
      })
    );
  }

  public couponApplied({
    checkout,
    origin = CheckoutOrigin.checkout,
    mobilePosition,
  }: {
    checkout: CheckoutModel;
    origin?: CheckoutOrigin;
    mobilePosition?: string;
  }): void {
    void this.services.siteStore.webBiLogger.report(
      couponApplied({
        appName: ECOM_PLATFORM_CHECKOUT,
        cartId: checkout.cartId,
        catalogAppId: getCatalogAppIds(checkout),
        checkoutId: checkout.id,
        couponCode: checkout.appliedCoupon?.code,
        couponId: checkout.appliedCoupon?.id,
        type: checkout.appliedCoupon?.couponType,
        name: checkout.appliedCoupon?.name,
        itemType: getItemTypes(checkout),
        total_long: toBiAmount(checkout.appliedCoupon?.price.amount ?? /* istanbul ignore next */ 0),
        origin,
        mobilePosition,
        purchaseFlowId: checkout.purchaseFlowId,
      })
    );
  }

  public errorWhenApplyingACoupon({
    couponCode,
    applyCouponError,
    checkout,
    origin = CheckoutOrigin.checkout,
    mobilePosition,
  }: {
    couponCode: string;
    applyCouponError: CheckoutErrorModel;
    checkout: CheckoutModel;
    origin?: CheckoutOrigin;
    mobilePosition?: string;
  }): void {
    void this.services.siteStore.webBiLogger.report(
      errorWhenApplyingACoupon({
        appName: ECOM_PLATFORM_CHECKOUT,
        cartId: checkout.cartId,
        catalogAppId: getCatalogAppIds(checkout),
        checkoutId: checkout.id,
        couponCode,
        errorCode: getCouponErrorCodeForBi(applyCouponError.code),
        itemType: getItemTypes(checkout),
        origin,
        mobilePosition,
        purchaseFlowId: checkout.purchaseFlowId,
        isMember: this.services.memberService.isMember(),
      })
    );
  }

  public giftCardCheckoutClickOnCheckbox(checkout: CheckoutModel, mobilePosition?: string): void {
    void this.services.siteStore.webBiLogger.report(
      giftCardCheckoutClickOnCheckbox({
        additionalFeesPrice: getAdditionalFeesPrice(checkout),
        appName: ECOM_PLATFORM_CHECKOUT,
        cartId: checkout.cartId,
        catalogAppId: getCatalogAppIds(checkout),
        checkoutId: checkout.id,
        itemType: getItemTypes(checkout),
        numberOfAdditionalFees: getNumberOfAdditionalFees(checkout),
        mobilePosition,
        purchaseFlowId: checkout.purchaseFlowId,
        isMember: this.services.memberService.isMember(),
      })
    );
  }

  public giftCardCheckoutClickApply(checkout: CheckoutModel, mobilePosition?: string): void {
    void this.services.siteStore.webBiLogger.report(
      giftCardCheckoutClickApply({
        additionalFeesPrice: getAdditionalFeesPrice(checkout),
        appName: ECOM_PLATFORM_CHECKOUT,
        cartId: checkout.cartId,
        catalogAppId: getCatalogAppIds(checkout),
        checkoutId: checkout.id,
        itemType: getItemTypes(checkout),
        numberOfAdditionalFees: getNumberOfAdditionalFees(checkout),
        mobilePosition,
        purchaseFlowId: checkout.purchaseFlowId,
        isMember: this.services.memberService.isMember(),
      })
    );
  }

  public giftCardCheckoutRemoveCode(checkout: CheckoutModel, mobilePosition?: string): void {
    void this.services.siteStore.webBiLogger.report(
      giftCardCheckoutRemoveCode({
        additionalFeesPrice: getAdditionalFeesPrice(checkout),
        appName: ECOM_PLATFORM_CHECKOUT,
        cartId: checkout.cartId,
        catalogAppId: getCatalogAppIds(checkout),
        checkoutId: checkout.id,
        giftCardId: checkout.giftCard?.id,
        itemType: getItemTypes(checkout),
        numberOfAdditionalFees: getNumberOfAdditionalFees(checkout),
        mobilePosition,
        purchaseFlowId: checkout.purchaseFlowId,
        isMember: this.services.memberService.isMember(),
      })
    );
  }

  public giftCardCheckoutCodeApplied(checkout: CheckoutModel, mobilePosition?: string): void {
    void this.services.siteStore.webBiLogger.report(
      giftCardCheckoutCodeApplied({
        additionalFeesPrice: getAdditionalFeesPrice(checkout),
        appName: ECOM_PLATFORM_CHECKOUT,
        cartId: checkout.cartId,
        catalogAppId: getCatalogAppIds(checkout),
        checkoutId: checkout.id,
        giftCardId: checkout.giftCard?.id,
        isCartPaidOnlyWithGiftCard: isFullyPaidByGiftCard(checkout),
        itemType: getItemTypes(checkout),
        numberOfAdditionalFees: getNumberOfAdditionalFees(checkout),
        mobilePosition,
        purchaseFlowId: checkout.purchaseFlowId,
        isMember: this.services.memberService.isMember(),
      })
    );
  }

  public checkoutErrorWhenApplyingAGiftCard(
    applyGiftCardError: CheckoutErrorModel,
    checkout: CheckoutModel,
    mobilePosition?: string
  ): void {
    void this.services.siteStore.webBiLogger.report(
      checkoutErrorWhenApplyingAGiftCard({
        appName: ECOM_PLATFORM_CHECKOUT,
        cartId: checkout.cartId,
        catalogAppId: getCatalogAppIds(checkout),
        checkoutId: checkout.id,
        errorCode: applyGiftCardError.code,
        itemType: getItemTypes(checkout),
        mobilePosition,
        purchaseFlowId: checkout.purchaseFlowId,
        isMember: this.services.memberService.isMember(),
      })
    );
  }

  public checkoutMinimumOrderModalIsShownInCheckout(
    checkout: CheckoutModel,
    remaining: number,
    minimumOrderAmount: number,
    stage: string
  ): void {
    void this.services.siteStore.webBiLogger.report(
      checkoutMinimumOrderModalIsShownInCheckout({
        appName: ECOM_PLATFORM_CHECKOUT,
        cartId: checkout.cartId,
        catalogAppId: getCatalogAppIds(checkout),
        ecomId: checkout.ecomId,
        checkoutId: checkout.id,
        currency: checkout.currency,
        diffLong: toBiAmount(remaining),
        hasPickup: hasPickupOption(checkout),
        itemType: getItemTypes(checkout),
        minimumValueLong: toBiAmount(minimumOrderAmount),
        stage,
        purchaseFlowId: checkout.purchaseFlowId,
      })
    );
  }

  public checkoutMinimumOrderClickOnGotItInErrorModalInCheckout(
    checkout: CheckoutModel,
    remaining: number,
    minimumOrderAmount: number,
    stage: string
  ): void {
    void this.services.siteStore.webBiLogger.report(
      checkoutMinimumOrderClickOnGotItInErrorModalInCheckout({
        appName: ECOM_PLATFORM_CHECKOUT,
        cartId: checkout.cartId,
        catalogAppId: getCatalogAppIds(checkout),
        checkoutId: checkout.id,
        ecomId: checkout.ecomId,
        currency: checkout.currency,
        diffLong: toBiAmount(remaining),
        itemType: getItemTypes(checkout),
        minimumValueLong: toBiAmount(minimumOrderAmount),
        stage,
        purchaseFlowId: checkout.purchaseFlowId,
      })
    );
  }

  public checkoutErrorTrackingForDevelopers(error: string, data?: string): void {
    void this.services.siteStore.webBiLogger.report(
      checkoutErrorTrackingForDevelopers({
        error,
        data,
      })
    );
  }

  public clickPlaceOrderWithSubscription(
    checkout: CheckoutModel,
    defaultState: boolean,
    origin: CheckoutOrigin = CheckoutOrigin.checkout
  ): void {
    void this.services.siteStore.webBiLogger.report(
      clickPlaceOrderWithSubscription({
        additionalFeesPrice: getAdditionalFeesPrice(checkout),
        appName: ECOM_PLATFORM_CHECKOUT,
        cartId: checkout.cartId,
        catalogAppId: getCatalogAppIds(checkout),
        checkoutId: checkout.id,
        ecomId: checkout.ecomId,
        default_state: defaultState,
        itemType: getItemTypes(checkout),
        numberOfAdditionalFees: getNumberOfAdditionalFees(checkout),
        origin,
        purchaseFlowId: checkout.purchaseFlowId,
      })
    );
  }

  public checkoutClickOnCheckoutPolicies(checkout: CheckoutModel, linkLocation: string, policyType: string) {
    void this.services.siteStore.webBiLogger.report(
      checkoutClickOnCheckoutPolicies({
        appName: ECOM_PLATFORM_CHECKOUT,
        catalogAppId: getCatalogAppIds(checkout),
        checkoutId: checkout.id,
        linkLocation,
        policyType,
        purchaseFlowId: checkout.purchaseFlowId,
      })
    );
  }

  public loadCheckoutFailed() {
    void this.services.siteStore.webBiLogger.report(checkoutLoadingCheckoutPageFailForDev({}));
  }

  public checkoutPageLoad(checkout: CheckoutModel, stepsList: StepId[]): void {
    const itemType = getItemTypes(checkout);
    const buyerLanguage = checkout.buyerLanguage;
    const isBuyNowPayLaterOption = Boolean(checkout.payLater?.total.amount);
    const num_of_paymet_providers = this.services.checkoutSettingsService.checkoutSettings.activePaymentMethods?.length;
    const num_of_shipping = getNumOfShippingOptions(checkout);
    const numLineItems = checkout.lineItems.length;
    const shippingMethodType = getSelectedShippingMethodType(checkout.selectedShippingOption);
    const siteLanguage = checkout.siteLanguage;
    const savedAddress = hasSavedAddress(checkout);
    const isLean = isEligibleForLeanFlow({
      checkout,
      hasLeanFlowParam: this.services.navigationService.hasLeanFlowParam,
      isMember: this.services.memberService.isMember(),
      formsService: this.services.formsService,
    });

    void this.services.siteStore.webBiLogger.report(
      checkoutPageLoad({
        isOutOfIframe: true,
        additionalFeesPrice: getAdditionalFeesPrice(checkout),
        appName: ECOM_PLATFORM_CHECKOUT,
        cartId: checkout.cartId,
        ecomId: checkout.ecomId,
        cartType: getCartType(checkout),
        catalogAppId: getCatalogAppIds(checkout),
        channelType: checkout.channelType,
        checkoutId: checkout.id,
        contactId: checkout.buyerInfo.contactId,
        customFieldsTitle: getCustomFieldsTitles(checkout),
        first_stage: getFirstStage(this.services.navigationService.isFastFlow, stepsList[0]),
        flowType: getFlowType(this.services.navigationService.isFastFlow, isLean),
        is_member: this.services.memberService.isMember(),
        is_promo_code: shouldShowCouponSection(this.services.checkoutSettingsService.showCouponSP, checkout),
        is_with_ewallet_payment: true,
        is_with_offline_payment: isWithOfflinePaymentMethod(
          this.services.checkoutSettingsService.checkoutSettings.activePaymentMethods
        ),
        isGiftCard: shouldShowGiftCardSection({
          checkoutSettings: this.services.checkoutSettingsService.checkoutSettings,
          checkout,
        }),
        isPolicy:
          this.services.checkoutSettingsService.checkoutSettings.isThereAnyPolicyEnabled ||
          checkout.paymentPolicies.length > 0,
        itemType,
        origin: this.services.navigationService.origin,
        originType: this.services.navigationService.originType,
        purchaseFlowId: checkout.purchaseFlowId,
        shippingCountry: checkout.shippingDestination?.address.country,
        paymentProviders: this.services.checkoutSettingsService.checkoutSettings.paymentProviders,
        validationMessage: getValidations(checkout.violations),
        buyerLanguage,
        isBuyNowPayLaterOption,
        num_of_paymet_providers,
        num_of_shipping,
        numLineItems,
        shippingMethodType,
        siteLanguage,
        savedAddress,
        requiredPaymentPolicy: checkout.paymentPolicies.length > 0,
        savePaymentMethod: checkout.isCardTokenizationCheckout,
      })
    );
  }

  public checkoutPageFinishLoading(checkout: CheckoutModel, stepsList: StepId[]): void {
    const itemType = getItemTypes(checkout);
    const buyerLanguage = checkout.buyerLanguage;
    const isBuyNowPayLaterOption = Boolean(checkout.payLater?.total.amount);
    const num_of_paymet_providers = this.services.checkoutSettingsService.checkoutSettings.activePaymentMethods?.length;
    const num_of_shipping = getNumOfShippingOptions(checkout);
    const numLineItems = checkout.lineItems.length;
    const shippingMethodType = getSelectedShippingMethodType(checkout.selectedShippingOption);
    const siteLanguage = checkout.siteLanguage;
    const savedAddress = hasSavedAddress(checkout);
    const isLean = isEligibleForLeanFlow({
      checkout,
      hasLeanFlowParam: this.services.navigationService.hasLeanFlowParam,
      isMember: this.services.memberService.isMember(),
      formsService: this.services.formsService,
    });

    void this.services.siteStore.webBiLogger.report(
      checkoutCheckoutPageFinishLoadingSrc130Evid54({
        isOutOfIframe: true,
        additionalFeesPrice: getAdditionalFeesPrice(checkout),
        appName: ECOM_PLATFORM_CHECKOUT,
        cartId: checkout.cartId,
        cartType: getCartType(checkout),
        catalogAppId: getCatalogAppIds(checkout),
        channelType: checkout.channelType,
        checkoutId: checkout.id,
        contactId: checkout.buyerInfo.contactId,
        customFieldsTitle: getCustomFieldsTitles(checkout),
        first_stage: getFirstStage(this.services.navigationService.isFastFlow, stepsList[0]),
        flowType: getFlowType(this.services.navigationService.isFastFlow, isLean),
        is_member: this.services.memberService.isMember(),
        is_promo_code: shouldShowCouponSection(this.services.checkoutSettingsService.showCouponSP, checkout),
        is_with_ewallet_payment: true,
        is_with_offline_payment: isWithOfflinePaymentMethod(
          this.services.checkoutSettingsService.checkoutSettings.activePaymentMethods
        ),
        isGiftCard: shouldShowGiftCardSection({
          checkoutSettings: this.services.checkoutSettingsService.checkoutSettings,
          checkout,
        }),
        isPolicy:
          this.services.checkoutSettingsService.checkoutSettings.isThereAnyPolicyEnabled ||
          checkout.paymentPolicies.length > 0,
        itemType,
        origin: this.services.navigationService.origin,
        originType: this.services.navigationService.originType,
        purchaseFlowId: checkout.purchaseFlowId,
        shippingCountry: checkout.shippingDestination?.address.country,
        paymentProviders: this.services.checkoutSettingsService.checkoutSettings.paymentProviders,
        validationMessage: getValidations(checkout.violations),
        buyerLanguage,
        isBuyNowPayLaterOption,
        num_of_paymet_providers,
        num_of_shipping,
        numLineItems,
        shippingMethodType,
        siteLanguage,
        savedAddress,
        requiredPaymentPolicy: checkout.paymentPolicies.length > 0,
        savePaymentMethod: checkout.isCardTokenizationCheckout,
      })
    );
  }

  public checkoutIntegratingFormsFailure(requestedTemplates: string[], error: any, checkout: CheckoutModel): void {
    const isLean = isEligibleForLeanFlow({
      checkout,
      hasLeanFlowParam: this.services.navigationService.hasLeanFlowParam,
      isMember: this.services.memberService.isMember(),
      formsService: this.services.formsService,
    });

    void this.services.siteStore.webBiLogger.report(
      checkoutIntegratingFormsFailure({
        appName: ECOM_PLATFORM_CHECKOUT,
        cartId: checkout?.cartId,
        catalogAppId: checkout ? getCatalogAppIds(checkout) : undefined,
        checkoutId: checkout?.id,
        flowType: getFlowType(this.services.navigationService.isFastFlow, isLean),
        formsTemplatesId: requestedTemplates.toString(),
        networkError: JSON.stringify(error),
        purchaseFlowId: checkout?.purchaseFlowId,
      })
    );
  }

  public selectPaymentCategory(checkout: CheckoutModel, {paymentMethod}: {paymentMethod?: string}): void {
    void this.services.siteStore.webBiLogger.report(
      selectPaymentCategory({
        additionalFeesPrice: getAdditionalFeesPrice(checkout),
        appName: ECOM_PLATFORM_CHECKOUT,
        cartId: checkout.cartId,
        catalogAppId: getCatalogAppIds(checkout),
        checkoutId: checkout.id,
        itemType: getItemTypes(checkout),
        numberOfAdditionalFees: getNumberOfAdditionalFees(checkout),
        paymentMethod,
        paymentCategory: getPaymentCategory(paymentMethod),
        purchaseFlowId: checkout.purchaseFlowId,
      })
    );
  }

  public paymentDetailsCompleted(checkout: CheckoutModel, {paymentMethod}: {paymentMethod?: string}): void {
    void this.services.siteStore.webBiLogger.report(
      paymentDetailsCompleted({
        additionalFeesPrice: getAdditionalFeesPrice(checkout),
        numberOfAdditionalFees: getNumberOfAdditionalFees(checkout),
        appName: ECOM_PLATFORM_CHECKOUT,
        cartId: checkout.cartId,
        catalogAppId: getCatalogAppIds(checkout),
        checkoutId: checkout.id,
        ecomId: checkout.ecomId,
        cartType: getCartType(checkout),
        itemType: getItemTypes(checkout),
        paymentProvider: paymentMethod,
        purchaseFlowId: checkout.purchaseFlowId,
      })
    );
  }

  public checkoutClickLoginInCheckout(checkout: CheckoutModel): void {
    const isLean = isEligibleForLeanFlow({
      checkout,
      hasLeanFlowParam: this.services.navigationService.hasLeanFlowParam,
      isMember: this.services.memberService.isMember(),
      formsService: this.services.formsService,
    });

    void this.services.siteStore.webBiLogger.report(
      checkoutClickLoginInCheckout({
        additionalFeesPrice: getAdditionalFeesPrice(checkout),
        appName: ECOM_PLATFORM_CHECKOUT,
        cartId: checkout.cartId,
        cartType: getCartType(checkout),
        catalogAppId: getCatalogAppIds(checkout),
        checkoutId: checkout.id,
        flowType: getFlowType(this.services.navigationService.isFastFlow, isLean),
        is_member: this.services.memberService.isMember(),
        itemType: getItemTypes(checkout),
        numberOfAdditionalFees: getNumberOfAdditionalFees(checkout),
        owner_id: this.services.siteStore.ownerId,
        purchaseFlowId: checkout.purchaseFlowId,
      })
    );
  }

  public clickOnCheckoutWithEWallet(checkout: CheckoutModel): void {
    void this.services.siteStore.webBiLogger.report(
      clickOnCheckoutWithEWallet({
        additionalFeesPrice: getAdditionalFeesPrice(checkout),
        numberOfAdditionalFees: getNumberOfAdditionalFees(checkout),
        appName: ECOM_PLATFORM_CHECKOUT,
        cartType: getCartType(checkout),
        cartId: checkout.cartId,
        catalogAppId: getCatalogAppIds(checkout),
        checkoutId: checkout.id,
        ecomId: checkout.ecomId,
        itemsCount: checkout.lineItems.length,
        num_of_shipping: getNumOfShippingOptions(checkout),
        origin: CheckoutOrigin.checkout,
        is_with_ewallet_payment: true,
        purchaseFlowId: checkout.purchaseFlowId,
      })
    );
  }

  public checkoutClickLogoutInCheckout(checkout: CheckoutModel): void {
    const isLean = isEligibleForLeanFlow({
      checkout,
      hasLeanFlowParam: this.services.navigationService.hasLeanFlowParam,
      isMember: this.services.memberService.isMember(),
      formsService: this.services.formsService,
    });

    void this.services.siteStore.webBiLogger.report(
      clickLogoutInCheckout({
        additionalFeesPrice: getAdditionalFeesPrice(checkout),
        appName: ECOM_PLATFORM_CHECKOUT,
        cartId: checkout.cartId,
        cartType: getCartType(checkout),
        catalogAppId: getCatalogAppIds(checkout),
        checkoutId: checkout.id,
        flowType: getFlowType(this.services.navigationService.isFastFlow, isLean),
        is_member: this.services.memberService.isMember(),
        itemType: getItemTypes(checkout),
        owner_id: this.services.siteStore.ownerId,
        numberOfAdditionalFees: getNumberOfAdditionalFees(checkout),
        savedAddress: hasSavedAddress(checkout),
        purchaseFlowId: checkout.purchaseFlowId,
      })
    );
  }

  public checkoutLogoutDialogClickReturnToCheckout(checkout: CheckoutModel): void {
    const isLean = isEligibleForLeanFlow({
      checkout,
      hasLeanFlowParam: this.services.navigationService.hasLeanFlowParam,
      isMember: this.services.memberService.isMember(),
      formsService: this.services.formsService,
    });

    void this.services.siteStore.webBiLogger.report(
      clickOnReturnToCheckoutInLogoutModal({
        additionalFeesPrice: getAdditionalFeesPrice(checkout),
        appName: ECOM_PLATFORM_CHECKOUT,
        cartId: checkout.cartId,
        cartType: getCartType(checkout),
        catalogAppId: getCatalogAppIds(checkout),
        checkoutId: checkout.id,
        flowType: getFlowType(this.services.navigationService.isFastFlow, isLean),
        is_member: this.services.memberService.isMember(),
        itemType: getItemTypes(checkout),
        numberOfAdditionalFees: getNumberOfAdditionalFees(checkout),
        savedAddress: hasSavedAddress(checkout),
        purchaseFlowId: checkout.purchaseFlowId,
      })
    );
  }

  public checkoutClickOnCheckboxInReviewAndPlaceOrderStepSrc130Evid2({
    checkout,
    fieldType,
    newValue,
    hasLineItemPolicy,
  }: {
    checkout: CheckoutModel;
    fieldType: string;
    newValue: string;
    hasLineItemPolicy: boolean;
  }): void {
    void this.services.siteStore.webBiLogger.report(
      checkoutClickOnCheckboxInReviewAndPlaceOrderStepSrc130Evid2({
        checkoutId: checkout.id,
        purchaseFlowId: checkout.purchaseFlowId,
        catalogAppIds: getCatalogAppIds(checkout),
        fieldType,
        newValue,
        hasLineItemPolicy,
      })
    );
  }

  public checkoutClickToRemoveItemDueToCheckoutRestrictionsSrc130Evid74({
    checkout,
    message,
    lineItemId,
    suggestedFix,
  }: {
    checkout: CheckoutModel;
    message?: string;
    lineItemId?: string;
    suggestedFix?: string;
  }): void {
    void this.services.siteStore.webBiLogger.report(
      checkoutClickToRemoveItemDueToCheckoutRestrictionsSrc130Evid74({
        message,
        lineItemId,
        purchaseFlowId: checkout.purchaseFlowId,
        checkoutId: checkout.id,
        suggestedFix,
      })
    );
  }
}
